<template>
  <div class="message-wrapper mt15" @scroll="scrollHandler" ref="msgContainer">
    <template v-if="msgList.length > 0">
      <div class="message-list">
        <div v-for="(item, index) in msgList" :key="index" class="message-item mb20">
          <div class="d-flex message-item_user">
            <span class="textmsgpoints">
              {{item.from_institution ? item.from_institution.name : '-'}} / {{(item.user && item.user.name) || '-'}}
            </span>
            <span style="padding: 0 5px;" class="textprimary">to</span>
            <span class="textmsgpoints">{{item.to_institution ? item.to_institution.name : '-'}}</span>
          </div>
          <div class="message-item_txt mt8 textprimary">{{item.content}}</div>
          <div class="messge-item_time textlightgray12 mt8">{{item.created_at}}</div>
        </div>
        <div class="message-loadmore d-flex flex-center">
          <div v-if="isLoading" class="loading-spinner">
            <svg viewBox="25 25 50 50" class="loading-circular"><circle cx="50" cy="50" r="20" fill="none" class="loading-path"></circle></svg>
          </div>
          <div class="textprimary" style="padding-left: 10px;">{{ loadmoreTxt }}</div>
        </div>
      </div>
    </template>
    <div class="flic-empty" v-if="hasLoaded && msgList.length == 0">No Data</div>
  </div>
</template>

<script>
import { getMsgList } from '@/services'
import Utils from '@/utils/Utils'
export default {
  data() {
    return {
      msgList: [],
      pageSize: 20,
      total: 0,
      page: 0,
      hasLoaded: false,
      totalPages: 1,
      loadmoreTxt: '暂无更多',
      isLoading: false,
      firstMsgId: '',
      showTips: false
    }
  },
  mounted () {
    this.initMsgList()
  },
  methods: {
    async initMsgList () {
      try {
        this.page += 1
        const res = await getMsgList({
          page: this.page,
          per_page: this.pageSize,
          order: 'created_at desc'
        })
        if (res.data.messages) {
          if (this.page == 1 && res.data.messages.length > 0) {
            this.firstMsgId = res.data.messages[0].id
          }
          this.msgList = this.msgList.concat(res.data.messages)
          this.total = res.data.page.total_count
          this.totalPages = res.data.page.total_pages
          // this.$nextTick(() => {
          //   this.scrollIntoView('message-loadmore')
          // })
        }
      } catch (error) {}
      this.isLoading = false
      this.hasLoaded = true
    },
    async getMsgRealtime () {
      if (this.showTips) return
      try {
        const res = await getMsgList({
          page: this.page,
          per_page: 100,
          order: 'created_at desc'
        }, false)
        if (res.data.messages) {
          const { messages } = res.data
          const idx = messages.findIndex((d) => d.id == this.firstMsgId)
          if (idx > 0) {
            if (this.page > 1 && !this.showTips) {
              this.showTips = true
              Utils.showNoticeDialog(this, 'New Messages',
              'Click to refresh this page', () => {
                this.showTips = false
                this.reload()
              })
            } else if(this.page == 1) {
              this.firstMsgId = messages[idx - 1].id
              const list = messages.slice(0, idx)
              this.msgList = list.concat(this.msgList).slice(0, this.pageSize)
              this.scrollIntoView('message-list')
            }
          }
        }
      } catch (error) {}
    },
    scrollIntoView(classname) { 
      const container = this.$refs.msgContainer
      container.querySelector('.' + classname).scrollIntoView({ behavior: 'smooth' })
    },
    reload () {
      this.page = 0
      this.msgList = []
      this.hasLoaded = false
      this.initMsgList()
    },
    addNewMessage (msg) {
      if (msg.id) this.firstMsgId = msg.id
      this.msgList.unshift(msg)
      this.msgList = this.msgList.slice(0, this.pageSize)
    },
    scrollHandler (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (this.page >= this.totalPages) {
        this.loadmoreTxt = '暂无更多'
        return
      }
      if (scrollTop + clientHeight === scrollHeight){
        this.isLoading = true
        this.loadmoreTxt = '加载中'
        this.initMsgList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-wrapper {
  width: 100%;
  height: calc(100% - 235px);
  border: 1px solid #D9D9D9;
  padding: 25px 40px 25px 24px;
  box-sizing: border-box;
  overflow-y: auto;
}
.message-list {
  height: 100%;
}
.message-item {
  width: 100%;
}

.loading-circular {
  height: 26px;
  width: 26px;
  animation: loading-rotate 2s linear infinite;
}

.message-loadmore {
  height: 70px;
}

.loading-path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90,150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: $text-color-darkblue;
  stroke-linecap: round;
}

@keyframes loading-rotate {
  to {
     transform: rotate(1turn)
  }
}
</style>