<template>
  <div class="messagebox spacebetween">
    <div class="messagebox_left">
      <div class="textdarkblue-bold16">System Notifications</div>
      <el-tabs class="mt20" v-model="status" type="card" @tab-click="tabChangeHandler">
        <el-tab-pane :label="`All(${total})`" name="">
        </el-tab-pane>
        <el-tab-pane :label="`Unread(${unreadTotal})`" name="unread">
        </el-tab-pane>
        <el-tab-pane :label="`Read(${readTotal})`" name="read">
        </el-tab-pane>
      </el-tabs>
      <NoticeList ref="notices" @changetotal="getTotalHandler" />
    </div>
    <div class="messagebox_right">
      <div class="textdarkblue-bold16">Message</div>
      <MessageList ref="message" />
      <div class="mt20">
        <el-form ref="msgForm" :model="msgForm" label-width="95px">
          <el-form-item label="Message To">
            <el-select 
              v-model="msgForm.to_institution_id"
              placeholder="please select ..."
              style="width: 163px;"
            >
              <el-option
                v-for="item in institutionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="" label-width="0">
            <el-input
              type="textarea"
              v-model="msgForm.content"
              placeholder="Input your message here..."
              :autosize="{ minRows: 3, maxRows: 3}"
            />
          </el-form-item>
          <div class="d-flex flex-center">
            <el-button type="primary" @click="sendHandler">Send</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import NoticeList from '../components/NoticeList'
import MessageList from '../components/MessageList'
import {
  getInstitutionList,
  createMsg,
  getNoticeList
} from '@/services'
import Utils from '@/utils/Utils'
import { mapState } from 'vuex'
export default {
  components: {
    NoticeList,
    MessageList
  },
  data () {
    return {
      status: '',
      total: 0,
      readTotal: 0,
      unreadTotal: 0,
      msgForm: {
        to_institution_id: '',
        content: ''
      },
      institutionList: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  mounted () {
    this.initData()
    this.initFormData()
    this.initNoticeNum('read')
    this.initNoticeNum('unread')
    this.getMsgNoticeRealtime()
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) clearInterval(this.timer)
    Utils.closeAllNotification()
    next()
  },
  unmounted () {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    initData (val) {
      if (this.$refs.notices) {
        this.$refs.notices.reload(val || '', true)
      }
    },
    async initNoticeNum (status) {
      try {
        const res = await getNoticeList({
          page: 1,
          per_page: 10,
          status_eq: status
        }, false)
        if (res.data.notices) {
          this[status + 'Total'] = res.data.page.total_count
        }
      } catch (error) {}
    },
    getTotalHandler (val, type) {
      if (type == '') {
        this.total = val
      } else if(type == 'other'){
        this.readTotal += val
        if (this.unreadTotal > 0) this.unreadTotal -= val
      } else {
        this[type + 'Total'] = val
      }
    },
    getMsgNoticeRealtime () {
      this.timer = setInterval(async () => {
        if (this.$refs.notices) {
          this.$refs.notices.getRealtimeNotice()
        }
        if (this.$refs.message) this.$refs.message.getMsgRealtime()
      }, 4500)
    },
    tabChangeHandler (element) {
      this.initData(element.name)
    },
    async initFormData() {
      let kind_eq = 'PHI'
      if (this.user && this.user.institution) {
        if (this.user.institution.kind == 'PHI') {
          kind_eq = 'TCF'
        }
      }
      try {
        const res = await getInstitutionList({kind_eq}, false)
        if (res.data.institutions) {
          this.institutionList = res.data.institutions
        }
      } catch (error) {
        console.log(error)
      }
    },
    async sendHandler () {
      this.isSubmitting = false
      const {content, to_institution_id} = this.msgForm
      if (!to_institution_id) {
        this.$message.error('Please select Institution')
        return
      }
      if (!content) {
        this.$message.error('Please enter the Content')
        return
      }
      if (!this.isSubmitting) {
        this.isSubmitting = true
        try {
          const res = await createMsg({
            message: {
              ...this.msgForm
            }
          })
          if (res.data.message) {
            this.msgForm = {
              to_institution_id: '',
              content: ''
            }
            this.$refs.message.addNewMessage(res.data.message)
          }
        } catch (error) {}
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.messagebox {
  display: flex;
  height: 100%;

  &_left {
    width: calc(100% - 525px);
    background: #fff;
    border-radius: 2px;
    padding: 18px;
    box-sizing: border-box;
    height: 100%;
    min-width: 450px;
  }
  &_right {
    width: 510px;
    background: #fff;
    border-radius: 2px;
    padding: 18px 23px;
    box-sizing: border-box;
    height: 100%;
  }
}
</style>