<template>
  <div class="notice-wrapper">
    <div class="notice-list_wrapper" ref="noticeContainer">
      <div class="notice-list">
        <template v-if="noticeList.length > 0">
          <div 
            v-for="(notice, idx) in noticeList"
            :key="notice.id"
            class="notice-item d-flex spacebetween"
            :class="{'notice-item_unread': !notice.read}"
            @click="() => readHandler(notice, idx)"
          >
            <div class="notice-item_dot" v-if="!notice.read"></div>
            <div class="notice-item_content" v-html="notice.content"></div>
            <div class="notice-item_time">{{ notice.created_at }}</div>
          </div>
        </template>
        <div v-if="hasLoaded && noticeList.length == 0" class="flic-empty">
          No Data
        </div>
      </div>
    </div>
    <div class="mt20">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="pageSize"
        @size-change="sizeChangeHandler"
        @current-change="currentChangeHandler"
        :page-sizes="[20, 40, 60, 80, 100]"
        :current-page.sync = "page"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getNoticeList,
  postReadNotice
} from '@/services'
import Utils from '@/utils/Utils'
export default {
  data () {
    return {
      noticeList: [],
      pageSize: 20,
      total: 0,
      page: 1,
      status: '',
      hasLoaded: false,
      hasTips: false
    }
  },
  methods: {
    async initNoticeList (status, isAssign) {
      try {
        if (isAssign) this.status = status
        const res = await getNoticeList({
          page: this.page,
          per_page: this.pageSize,
          status_eq: status || this.status,
          order: 'created_at desc'
        })
        if (res.data.notices) {
          this.noticeList = res.data.notices
          this.total = res.data.page.total_count
          this.$emit('changetotal', this.total, status)
        }
      } catch (error) {}
      this.hasLoaded = true
    },
    reload (status, isAssign) {
      this.page = 1
      this.noticeList = []
      this.hasLoaded = false
      this.initNoticeList(status, isAssign)
    },
    sizeChangeHandler (val) {
      this.page = 1
      this.pageSize = val
      this.initNoticeList(this.status)
    },
    currentChangeHandler (val) {
      this.page = val
      this.initNoticeList(this.status)
    },
    async getRealtimeNotice () {
      try {
        const res = await getNoticeList({
          status_eq: 'unseen',
          order: 'created_at desc'
        }, false)
        if (res.data.notices && res.data.notices.length > 0) {
          const that = this
          if (!that.hasTips && that.page > 1) {
            that.hasTips = true
            Utils.showNoticeDialog(that, `New System Notifications`,
            'Click to refresh this page', () => {
              that.reload('', true)
              that.hasTips = false
            })
          } else if (that.page == 1) {
            const { notices } = res.data
            this.noticeList = notices.concat(this.noticeList).slice(0, that.pageSize)
            this.scrollToTop()
          }
        }
      } catch (error) {}
    },
    scrollToTop() { 
      const container = this.$refs.noticeContainer
      container.querySelector('.notice-list').scrollIntoView({ behavior: 'smooth' })
    },
    readHandler (item, idx) {
      const that = this
      if (!item.read) {
        this.$alert(`<div class="textdarkblue">${item.content}</div>`, 'Acknowledgement', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Ok',
          customClass: 'alert-dialog',
          callback: async (action) => {
            if (action == 'confirm') {
              try {
                const res = await postReadNotice({notice: {ids: `${item.id}`}})
                if (res.code >= 200 && res.code < 300) {
                  that.noticeList[idx].read = true
                  this.$emit('changetotal', 1, 'other')
                }
              } catch (error) {
                
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-wrapper {
  height: calc(100% - 95px);
}
.notice-list_wrapper {
  height: calc(100% - 52px);
  overflow-y: auto;
}
.notice-item {
  width: 100%;
  position: relative;
  padding: 24px 39px;
  border-bottom: $border-style;
  box-sizing: border-box;
  &_unread {
    background: #f9f9f9;
  }
  &_dot {
    width: 11px;
    height: 11px;
    background: #FF7272;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &_content {
    width: 400px;
  }
  &_time {
    font-size: 12px;
    color: $text-color-darkblue;
    margin-left: 23px;
  }
}
</style>